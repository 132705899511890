:root {
  // background color
  --palette-background-default: #ecedf2;

  // text color
  --palette-text: #000000;
  --palette-highlight-text: #e2971b;
  --palette-sub-text: #707070;
  --palette-caption-text: #1b50b7;
  --palette-contrast-text: #ffffff;
  --palette-disabled-text: #b9b9b9;

  --palette-foot-note: #01194b;
}

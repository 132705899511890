$space-top: 125px;

.ant-notification {
  margin: 0;

  .notification__custom {
    top: $space-top;
    // width: auto;
    width: 350px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #8b8b8b9a;
    border-radius: 3px;
    opacity: 1;

    .ant-notification-notice-content {
      .ant-notification-notice-message {
        max-width: 350px;
        margin: 0;

        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
          var(--unnamed-line-spacing-14) var(--unnamed-font-family-sf-pro);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        opacity: 1;
      }
    }

    .ant-notification-notice-close {
      display: none;
    }
  }
}

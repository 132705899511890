$modal-body-max-height: 94vh;

@mixin box-modal() {
  overflow: hidden;
  background: transparent;
  border-radius: 10px;
}

.backdrop--modal__container {
  @include box-modal();

  .ant-modal {
    padding: 0;
    @include box-modal();

    .ant-modal-content {
      border-radius: var(--theme-border-radius-10);
      @include box-modal();

      .ant-modal-body {
        padding: 0;
        max-height: $modal-body-max-height;
        @include box-modal();
        overflow-y: auto;
        overflow-x: hidden;
        @include webkitScrollbar();
      }
    }
  }
}

.cdk-overlay-backdrop {
  background-color: rgba(#203347, 0.4);

  &.cdk-overlay-backdrop__custom {
    background-color: #020202b2;
  }
}

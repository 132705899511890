$brandMainColor: #01194b;
$brandMainLightColor: #7f96c7;
$brandSubColor: #e2961b;
$fontColor: #24292e;
$grayColor: #707070; // #5f6c72;
$borderColor: #e1e7f0;
$backgroundColor: #f2f5f7;
$backgroundColorModal: #f6f8fa;
$cautionColor: #f0b40c;
$warningColor: #d61543;
$shadowColor: rgba(0, 36, 47, 0.122);
$textColor: #586069;
$deActiveColor: #2b3d63;
$primaryHighLight: #d1dcf1;
$cautionHighLight: #fdfcd4;
$impressHighLight: #cb7e00;
$focusHighLight: white;
$infoHighLight: #1b50b7;
$statusDefault: #cfd7e7;
$deActiveSubColor: #bc965a;
$deActiveMainColor: #20386c;
$shadow: #00000029;
$darkNight: black;

$modal-content-maximum-height: 240px;
$modal-box-maximum-height: calc(#{$modal-content-maximum-height} + 80px);

/* ----------------------------------------- */
/*        Class global <=> inline            */
/* ----------------------------------------- */
// Display
.d {
  &__none {
    display: none !important;
  }

  &__inline-block {
    display: inline-block !important;
  }
}

.visibility {
  &__hidden {
    visibility: hidden !important;
  }

  &__visible {
    visibility: visible !important;
  }
}

// Cursor
.clicked {
  cursor: pointer !important;
}

.non-clicked {
  pointer-events: none !important;
}

// Fonts
.font--bold {
  font-weight: bold !important;
}

.hidden__scroll {
  overflow: hidden !important;
}

/* ----------------------------------------- */
/*             Styling global                */
/* ----------------------------------------- */

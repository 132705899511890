#client {
  .auth__section {
    .actions {
      width: 100%;

      app-button {
        width: 100%;

        .button__section {
          button {
            width: 100%;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 16px/19px var(--unnamed-font-family-sf-pro);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-ffffff);
          }
        }
      }
    }
  }
}

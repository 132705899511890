#client {
  nz-select {
    .ant-select-selector {
      box-shadow: 0px 0px 2px #00000029;
      border: 0.5px solid #707070;
      border-radius: 3px;
      opacity: 1;
    }

    .ant-select-arrow {
      color: #01194b;
    }
  }
}

// cdk overlay dropdown
.ant-select-dropdown {
  margin-top: 6px;
  box-shadow: 0px 0px 10px #00000017;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border: 0.2px solid #989898;
  border-radius: 4px;
}

/* Custom overlay-panel of nz select dropdown */
.fb-cdk-overlay-panel-select-date,
.fb-cdk-overlay-panel-select-tag {
  .ant-select-dropdown {
    padding-top: 6px;

    .ant-select-item {
      display: flex;
      align-items: center;
      height: 28px;
      padding-left: var(--space-2xl);
    }
  }
}

.fb-cdk-overlay-panel-select-user,
.fb-cdk-overlay-panel-select-tag {
  .ant-select-item-option-disabled {
    background: none;
    height: auto;
    cursor: none;
    pointer-events: none;
  }
}

.fb-cdk-overlay-panel-select-date .cdk-virtual-scroll-viewport {
  min-height: 160px;
}
/* Custom overlay-panel of nz select dropdown */

#client {
  nz-range-picker {
    border-radius: 4px;
    padding: 4px 8px;
    border: 0.5px solid #b8b8b8;
    box-shadow: none;

    .ant-picker-input {
      input {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-8) /
          var(--unnamed-line-spacing-10) var(--unnamed-font-family-sf-pro);
        letter-spacing: var(--unnamed-character-spacing-0);
      }
    }

    .ant-picker-range-separator {
      width: 31px;
      height: 0.5px;
      background-color: #afafaf;
      margin-right: 10px;

      .ant-picker-separator {
        display: none;
      }
    }

    &:hover {
      border-color: #b8b8b8;
    }

    .ant-picker-active-bar {
      display: none;
    }
  }
}

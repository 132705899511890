.ant-dropdown {
  top: -6px;
  &-menu-item {
    min-width: 180px;
    &:hover {
      background: #dfdfdf !important;
      color: $brandMainColor;
    }
    font-size: 11px;
  }
}

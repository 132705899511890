:root {
  --space-small: 4px;
  --space-tiny: 8px;
  --space-sm: 10px;
  --space-md: 12px;
  --space-lg: 14px;
  --space-xl: 16px;
  --space-xxl: 18px;
  --space-2xl: 20px;
}

@font-face {
  font-family: 'SF Pro';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local('SF Pro'), local('SF Pro'), url('/assets/fonts/SF-Pro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local('SF Pro'), local('SF Pro'), url('/assets/fonts/SF-Pro-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local('SF Pro'), local('SF Pro'), url('/assets/fonts/SF-Pro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('SF Pro'), local('SF Pro'), url('/assets/fonts/SF-Pro-Semibold.otf') format('opentype');
}

$defaultFamily: 'SF Pro', sans-serif;

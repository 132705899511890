::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

[hidden] {
  display: none !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
  margin-right: auto;
  margin-left: auto;
  max-width: 0;
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  color: #6e6b7b;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.358rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    background 0s, border 0s;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #6e6b7b;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(37, 170, 225, 0.25);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.collapse:not(.show) {
  display: none;
}

.dropdown {
  position: relative;
}

.border {
  border: 1px solid #ebe9f1 !important;
}

.w-50 {
  width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.gap-20 {
  gap: 20px !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

.mt-50 {
  // margin-top: 0.5rem !important;
  margin-top: 5px !important;
}

.me-50 {
  margin-right: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 1.5rem !important;
}

.pe-25 {
  padding-right: 0.25rem !important;
}

.ps-25 {
  padding-left: 0.25rem !important;
}

.font-hiragino-sans {
  font-family: 'Hiragino Sans' !important;
}

.rounded {
  border-radius: 0.357rem !important;
}

.rounded-10 {
  border-radius: 1rem !important;
}

.visible {
  visibility: visible !important;
}

@mixin pc-only() {
  @media only screen and (min-width: 1144px) {
    @content;
  }
}

@mixin sp-only() {
  @media only screen and (max-width: 1144px) {
    @content;
  }
}

@mixin block() {
  display: block;
  &[hidden] {
    display: none;
  }
}

@mixin not-first-child() {
  ::ng-deep {
    > * {
      &:not(:first-child) {
        @content;
      }
    }
  }
}

@mixin label() {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  color: #24292e;
  &.-required {
    &::after {
      content: '*';
      display: inline-block;
      margin-left: 4px;
      font-weight: bold;
      color: $warningColor;
    }
  }
}

/** Flexbox Toolkit */
@mixin flex($direction: row, $justify-content: center, $align-items: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

/** Webkit-scrollbar Toolkit */
@mixin webkitScrollbar(
  $width: 3px,
  $boxShadow: inset 0 0 2px rgba(112, 112, 112, 0.37),
  $backgroundColor: rgba(112, 112, 112, 0.37),
  $outline: 1px solid rgba(112, 112, 112, 0.37),
  $padding-left: 5px,
  $borderRadius: 4px,
  $scrollbarTrackMarginTop: 0px,
  $scrollbarTrackMarginBottom: 0px
) {
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: $boxShadow;
    padding-left: $padding-left;
    margin-top: $scrollbarTrackMarginTop;
    margin-bottom: $scrollbarTrackMarginBottom;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $backgroundColor;
    // outline: $outline;
    border-radius: $borderRadius;
  }
}

@mixin three-dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin line-clamp($lineNumber: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lineNumber;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ::ng-deep code {
    display: -webkit-box;
    -webkit-line-clamp: $lineNumber;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

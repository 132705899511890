:root {
  /* Colors: */
  --unnamed-color-1a1a1a: #1a1a1a;
  --unnamed-color-b2b2b2: #b2b2b2;
  --unnamed-color-a7a7a7: #a7a7a7;
  --unnamed-color-fafafa: #fafafa;
  --unnamed-color-222222: #222222;
  --unnamed-color-e2e2e2: #e2e2e2;
  --unnamed-color-fdfcd4: #fdfcd4;
  --unnamed-color-7070707c: #7070707c;
  --unnamed-color-3f3e44: #3f3e44;
  --unnamed-color-cacbd0: #cacbd0;
  --unnamed-color-d8d8d8: #d8d8d8;
  --unnamed-color-dbdbdb: #dbdbdb;
  --unnamed-color-e21b1b: #e21b1b;
  --unnamed-color-b5b5b526: #b5b5b526;
  --unnamed-color-e2961b: #e2961b;
  --unnamed-color-f1e3d1: #f1e3d1;
  --unnamed-color-f1d1d1: #f1d1d1;
  --unnamed-color-e6d1f1: #e6d1f1;
  --unnamed-color-d1dcf1: #d1dcf1;
  --unnamed-color-1b50b733: #1b50b733;
  --unnamed-color-9e4bff: #9e4bff;
  --unnamed-color-ecedf2: #ecedf2;
  --unnamed-color-b9b9b9: #b9b9b9;
  --unnamed-color-70707080: #70707080;
  --unnamed-color-d1dcf153: #d1dcf153;
  --unnamed-color-020202b2: #020202b2;
  --unnamed-color-70707033: #70707033;
  --unnamed-color-9a9a9a: #9a9a9a;
  --unnamed-color-86d6d9: #86d6d9;
  --unnamed-color-cb7e00: #cb7e00;
  --unnamed-color-e5e5e5: #e5e5e5;
  --unnamed-color-f2f2f2: #f2f2f2;
  --unnamed-color-1b50b7: #1b50b7;
  --unnamed-color-707070: #707070;
  --unnamed-color-000000: #000000;
  --unnamed-color-b5b5b5: #b5b5b5;
  --unnamed-color-ffffff: #ffffff;
  --unnamed-color-7c7c7c: #7c7c7c;
  --unnamed-color-b2b2b23c: #b2b2b23c;
  --unnamed-color-5b78b526: #5b78b526;
  --unnamed-color-ffffff26: #ffffff26;
  --unnamed-color-01194b: #01194b;
  --unnamed-color-f5f6f8: #f5f6f8;
  --unnamed-color-0053ff: #0053ff;
  --unnamed-color-062663: #062663;
  --unnamed-color-1e5929: #1e5929;
  --unnamed-color-bd431d: #bd431d;

  /* Font/text values */
  --unnamed-font-family-sf-pro: 'SF Pro', sans-serif;
  --unnamed-font-family-hiragino-sans: Hiragino Sans;
  --unnamed-font-family-hiragino-kaku-gothic-pron: Hiragino Kaku Gothic ProN;
  --unnamed-font-style-normal: normal;
  --unnamed-font-style-italic: italic;
  --unnamed-font-weight-300: 300; // Medium
  // --unnamed-font-weight-600: 600; // Bold => not use => bold
  --unnamed-font-weight-bold: bold;
  --unnamed-font-weight-normal: normal; // Regular
  --unnamed-font-weight-medium: 500; // Semi Bold
  --unnamed-font-size-7: 7px;
  --unnamed-font-size-8: 8px;
  --unnamed-font-size-9: 9px;
  --unnamed-font-size-10: 10px;
  --unnamed-font-size-11: 11px;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-13: 13px;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-15: 15px;
  --unnamed-font-size-17: 17px;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-24: 24px;
  --unnamed-font-size-25: 25px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-character-spacing--0-54: -0.54px;
  --unnamed-character-spacing--0-32: -0.32px;
  --unnamed-character-spacing--0-49: -0.49px;
  --unnamed-character-spacing--0-45: -0.45px;
  --unnamed-line-spacing-4: 4px;
  --unnamed-line-spacing-8: 8px;
  --unnamed-line-spacing-10: 10px;
  --unnamed-line-spacing-11: 11px;
  --unnamed-line-spacing-12: 12px;
  --unnamed-line-spacing-13: 13px;
  --unnamed-line-spacing-14: 14px;
  --unnamed-line-spacing-15: 15px;
  --unnamed-line-spacing-16: 16px;
  --unnamed-line-spacing-17: 17px;
  --unnamed-line-spacing-18: 18px;
  --unnamed-line-spacing-19: 19px;
  --unnamed-line-spacing-20: 20px;
  --unnamed-line-spacing-21: 21px;
  --unnamed-line-spacing-22: 22px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-25: 25px;
  --unnamed-line-spacing-26: 26px;
  --unnamed-line-spacing-29: 29px;
  --unnamed-line-spacing-30: 30px;
  --unnamed-line-spacing-34: 34px;
}

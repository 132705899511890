.icon__section {
  @include flex();
  gap: 6px;
  // padding: 4px 12px;
  border-radius: 4px;

  .icon-action {
    display: flex;
    cursor: pointer;

    &:hover {
      svg {
        path {
          fill: var(--unnamed-color-1b50b7);
        }
      }
    }
  }

  .number {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-12) /
      var(--unnamed-line-spacing-14) var(--unnamed-font-family-sf-pro);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-707070);
  }

  &.activated {
    .icon-action {
      svg {
        path {
          fill: var(--unnamed-color-1b50b7);
        }
      }
    }

    .number {
      color: var(--unnamed-color-1b50b7);
    }
  }

  &:hover {
    background: var(--unnamed-color-f5f6f8);
  }
}

.breadcrumb {
  .breadcrumb__nav {
    @include flex($justify-content: space-between);
  }

  .breadcrumb__append {
    display: flex;
    gap: var(--space-2xl);
  }
}

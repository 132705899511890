button {
  cursor: pointer;

  &.button--secondary {
    background: var(--unnamed-color-01194b) 0% 0% no-repeat padding-box;
    border-radius: var(--theme-border-radius-6);
    opacity: var(--theme-opacity-show);
    color: var(--unnamed-color-ffffff);
    border: 0;

    &.large {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-13) /
        var(--unnamed-line-spacing-16) var(--unnamed-font-family-sf-pro);
      letter-spacing: var(--unnamed-character-spacing-0);
      height: 100%;
      padding: 0 50px;
    }
  }

  &:disabled,
  [disabled] {
    cursor: default;
    pointer-events: none;
    background: var(--palette-disabled-text);
    // TODO: (dong) check state button
  }
}

button,
.button {
  &:hover {
    opacity: 0.85 !important;
  }
}

#client {
  .ant-input-affix-wrapper,
  .ant-input-group {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 0.5px solid var(--unnamed-color-70707080);
    border-radius: 6px;
    padding: var(--space-tiny) var(--space-2xl);
    outline: 0;
    box-shadow: 0px 0px 3px #00000029;

    .ant-input-prefix {
      margin-right: 5px;
    }

    input {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-13) /
        var(--unnamed-line-spacing-21) var(--unnamed-font-family-sf-pro);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-707070);
      border: 0;
      padding: 0;
      border-color: initial;
      box-shadow: none;

      &::placeholder {
        opacity: 0.5;
      }
    }

    fa-icon {
      color: var(--unnamed-color-707070);
      opacity: 0.46;
    }
  }
}
